<template>
	<SelectTradeIn
		v-if="showSelectTradeIn"
		:heading="heading"
		:sub-heading="subHeading"
		:badge="badge"
		:button="button"
		:image="image"
		:trade-in-url="tradeInUrl"
	/>

	<ShowTradeIn
		v-else-if="tradeInItem"
		v-model:account-number="accountNumber"
		:heading="heading"
		:additional-heading="additionalHeading"
		:sub-heading="subHeading"
		:button="button"
		:badge="badge"
		:phone-data="phoneData"
		:show-account-number="showAccountNumber"
		:trade-in-is-completed="completedTradeIn"
		:show-button="!isInSummaryOrConfirmation"
		:show-return-to-trade-in-journey-button="showReturnToTradeInJourneyButton"
		:return-to-trade-in-journey-link="returnToTradeInJourneyLink"
		@remove="removeTradeInItem"
	/>
</template>

<script setup lang="ts">
import { dynamicContent } from "~/plugins/content-plugin";
import type { Texts } from "~/components/hardware-and-insurance/TradeIn/GizmoTradeInCard/tradeincard";
import { formatPrice } from "~/helpers/formatting/price";
import { useStore } from "vuex";
import validateAccountNumber from "~/helpers/validateAccountNumber";

const props = defineProps({
	texts: {
		type: Array as PropType<Texts>,
		default: () => [
			{
				type: "SELECT",
				textType: "HEADING",
				text: "Få penger for din gamle mobil",
			},
			{
				type: "SELECT",
				textType: "SUB_HEADING",
				text: "– rett på konto 💚",
			},
			{
				type: "SELECT",
				textType: "BADGE_TEXT",
				text: "Innbytte",
			},
			{
				type: "SELECT",
				textType: "BUTTON_TEXT",
				text: "Sjekk hvor mye du kan få",
			},

			{
				type: "SELECT_WITH_OFFSET",
				textType: "HEADING",
				text: "Bytt inn den gamle",
			},
			{
				type: "SELECT_WITH_OFFSET",
				textType: "SUB_HEADING",
				text: "– få den nye billigere 💚",
			},
			{
				type: "SELECT_WITH_OFFSET",
				textType: "BADGE_TEXT",
				text: "Innbytte",
			},
			{
				type: "SELECT_WITH_OFFSET",
				textType: "BUTTON_TEXT",
				text: "Sjekk hvor mye du kan få",
			},

			{
				type: "TRADE_IN",
				textType: "HEADING",
				text: 'Din estimerte verdi: <span class="variable"> estimate </span>',
			},
			{
				type: "TRADE_IN",
				textType: "SUB_HEADING",
				text: "Beløpet får du utbetalt ut på konto.",
			},
			{
				type: "TRADE_IN",
				textType: "BADGE_TEXT",
				text: "Innbytte",
			},
			{
				type: "TRADE_IN",
				textType: "BUTTON_TEXT",
				text: "Fjern",
			},

			{
				type: "TRADE_IN_WITH_OFFSET",
				textType: "HEADING",
				text: 'Din estimerte verdi: <span class="variable"> estimate </span>',
			},
			{
				type: "TRADE_IN_WITH_OFFSET",
				textType: "SUB_HEADING",
				text: "Beløpet trekkes fra totalprisen for ditt kjøp",
			},
			{
				type: "TRADE_IN_WITH_OFFSET",
				textType: "BADGE_TEXT",
				text: "Innbytte",
			},
			{
				type: "TRADE_IN_WITH_OFFSET",
				textType: "BUTTON_TEXT",
				text: "Fjern",
			},

			{
				type: "TRADE_IN_WITH_BONUS",
				textType: "HEADING",
				text: 'Din estimerte verdi: <span class="variable"> estimate </span>',
			},
			{
				type: "TRADE_IN_WITH_BONUS",
				textType: "ADDITIONAL_HEADING",
				text: 'Innbyttebonus: <span class="variable"> bonus </span>',
			},
			{
				type: "TRADE_IN_WITH_BONUS",
				textType: "SUB_HEADING",
				text: "Beløpet får du utbetalt ut på konto.",
			},
			{
				type: "TRADE_IN_WITH_BONUS",
				textType: "BADGE_TEXT",
				text: "Innbytte med bonus",
			},
			{
				type: "TRADE_IN_WITH_BONUS",
				textType: "BUTTON_TEXT",
				text: "Fjern",
			},

			{
				type: "TRADE_IN_WITH_OFFSET_WITH_BONUS",
				textType: "HEADING",
				text: 'Din estimerte verdi: <span class="variable"> estimate </span>',
			},
			{
				type: "TRADE_IN_WITH_OFFSET_WITH_BONUS",
				textType: "ADDITIONAL_HEADING",
				text: 'Innbyttebonus: <span class="variable"> bonus </span>',
			},
			{
				type: "TRADE_IN_WITH_OFFSET_WITH_BONUS",
				textType: "SUB_HEADING",
				text: "Beløpet trekkes fra totalprisen for ditt kjøp",
			},
			{
				type: "TRADE_IN_WITH_OFFSET_WITH_BONUS",
				textType: "BADGE_TEXT",
				text: "Innbytte med bonus",
			},
			{
				type: "TRADE_IN_WITH_OFFSET_WITH_BONUS",
				textType: "BUTTON_TEXT",
				text: "Fjern",
			},

			{
				type: "SWAP",
				textType: "HEADING",
				text: "Mobilen du bytter inn",
			},
			{
				type: "SWAP",
				textType: "BUTTON_TEXT",
				text: "Fjern",
			},
		],
	},
	image: {
		type: Object,
		default: () => ({
			image_md: { src: "/binaries/mobiltelefoner/innbytte/Innbytte.svg?w=100&h=100\n" },
			alt: "Innbytte",
		}),
	},
	tradeInUrl: {
		type: String,
		default: "/mobiltelefoner/innbytte/bestill/",
	},
	returnToTradeInJourneyLink: {
		type: String,
		default: "/mobiltelefoner/innbytte/bestill/gradering.page",
	},
	isSummary: {
		type: Boolean,
		default: false,
	},
	terminals: {
		type: Array,
		default: () => [],
	},
});

const {
	phoneData,
	tradeInItem,
	isSwap,
	removeTradeInItem,
	estimate,
	tradeInBonus,
	updateTradeInItem,
	completedTradeIn,
} = useTradeIn(props.terminals);
const store = useStore();
const route = useRoute();

const currentTerminal = computed(() => store.getters["checkout/currentTerminal"]);

const accountNumber = ref("");

const dynamicContentFunction = computed(() => {
	return dynamicContent({
		estimate: formatPrice.oneTime(estimate.value),
		bonus: formatPrice.oneTime(tradeInBonus.value),
	});
});

const hasOffset = computed(() => currentTerminal.value?.metadata?.downPayment);

const filteredTexts = computed(() => {
	return props.texts.filter((text) => {
		if (isSwap.value) {
			return text.type === "SWAP";
		} else if (hasOffset.value) {
			if (tradeInBonus.value !== 0) {
				return text.type === "TRADE_IN_WITH_OFFSET_WITH_BONUS";
			} else if (tradeInItem.value) {
				return text.type === "TRADE_IN_WITH_OFFSET";
			} else {
				return text.type === "SELECT_WITH_OFFSET";
			}
		} else {
			if (tradeInBonus.value !== 0) {
				return text.type === "TRADE_IN_WITH_BONUS";
			} else if (tradeInItem.value) {
				return text.type === "TRADE_IN";
			} else {
				return text.type === "SELECT";
			}
		}
	});
});

const heading = computed(() => {
	return dynamicContentFunction.value(filteredTexts.value.find((text) => text.textType === "HEADING")?.text);
});

const additionalHeading = computed(() => {
	return dynamicContentFunction.value(filteredTexts.value.find((text) => text.textType === "ADDITIONAL_HEADING")?.text);
});

const subHeading = computed(() => {
	return dynamicContentFunction.value(filteredTexts.value.find((text) => text.textType === "SUB_HEADING")?.text);
});

const badge = computed(() => {
	return dynamicContentFunction.value(filteredTexts.value.find((text) => text.textType === "BADGE_TEXT")?.text);
});

const button = computed(() => {
	return dynamicContentFunction.value(filteredTexts.value.find((text) => text.textType === "BUTTON_TEXT")?.text);
});

const showAccountNumber = computed(() => {
	return props.isSummary && !tradeInItem.value?.metaData?.accountNumber && !hasOffset.value;
});

const isInTradeInJourney = computed(() => {
	return route.path.includes("/mobiltelefoner/innbytte/bestill");
});

const showReturnToTradeInJourneyButton = computed(() => {
	return !isInTradeInJourney.value && !completedTradeIn.value;
});
const isInSummaryOrConfirmation = computed(() => {
	return route.path.includes("/checkout/summary") || route.path.includes("/ecommerce/receipt");
});

const showSelectTradeIn = computed(() => {
	if (isInSummaryOrConfirmation.value) {
		return false;
	} else {
		return !tradeInItem.value;
	}
});

watch(accountNumber, async (newValue) => {
	if (validateAccountNumber(newValue)) {
		await updateTradeInItem({ tradeInInfo: { accountNumber: newValue } });
	}
});
</script>

<style scoped lang="scss"></style>
