<template>
	<TnCard
		border
		dashed
		class="card"
	>
		<template
			v-if="badge"
			#badge
		>
			<TnBadge
				size="s"
				responsive
			>
				Innbytte
			</TnBadge>
		</template>

		<div class="card__content">
			<div class="card__content__graphic">
				<ImageResponsive v-bind="image" />
			</div>

			<div>
				<TnParagraph
					v-if="heading"
					bold
					responsive
					size="l"
				>
					{{ heading }}
				</TnParagraph>

				<TnParagraph
					v-if="subHeading"
					responsive
					size="l"
					color="gray"
				>
					{{ subHeading }}
				</TnParagraph>
			</div>
		</div>

		<div>
			<TnButton
				v-if="button"
				class="margin-top-m"
				responsive
				secondary
				@click="goToTradeIn"
			>
				{{ button }}</TnButton
			>
		</div>
	</TnCard>
</template>

<script setup lang="ts">
const props = defineProps({
	badge: {
		type: String,
		required: true,
	},
	heading: {
		type: String,
		required: true,
	},
	subHeading: {
		type: String,
		required: true,
	},
	button: {
		type: String,
		required: true,
	},
	image: {
		type: Object,
		required: true,
	},
	tradeInUrl: {
		type: String,
		required: true,
	},
});

const route = useRoute();

const goToTradeIn = () => {
	const path = route.path;
	navigateTo({ path: props.tradeInUrl, query: { path: path } });
};
</script>

<style scoped lang="scss">
.card {
	padding: $spacing-2xl $spacing-m $spacing-m;

	display: flex;
	flex-direction: column;

	&__content {
		display: flex;
		flex-direction: row;

		&__graphic {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 60px;
			height: 60px;
			flex-shrink: 0;
			flex-grow: 0;

			:deep() {
				> * {
					max-height: 100%;
					max-width: 100%;
				}
			}
		}
	}
}
</style>
