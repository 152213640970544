<template>
	<TnCard
		class="trade-in-card"
		border
	>
		<template
			v-if="badge"
			#badge
		>
			<TnBadge category="offer">
				{{ badge }}
			</TnBadge>
		</template>

		<div class="trade-in-card__content">
			<div class="trade-in-card__content__phone">
				<div class="trade-in-card__content__phone__info">
					<NuxtImg
						v-if="imgSrc && !showFallback"
						class="trade-in-card__content__phone__info__img"
						:src="imgSrc"
						@error="showFallback = true"
					/>

					<TnProductIcon
						v-else-if="!imgSrc || showFallback"
						name="FallbackPhonePlan"
					/>

					<div class="trade-in-card__content__phone__info__text">
						<TnParagraph
							bold
							responsive
							size="l"
						>
							{{ phoneData?.name }}
						</TnParagraph>

						<TnParagraph
							responsive
							size="m"
							color="gray"
						>
							{{ phoneData?.color ?? "" }}{{ phoneData?.color ? ", " : "" }}{{ phoneData?.memorySize ?? "" }}
						</TnParagraph>
					</div>
				</div>

				<div class="trade-in-card__content__phone__button">
					<TnButton
						v-if="button && showButton"
						tertiary
						icon-right="Trashcan"
						size="xs"
						@click="$emit('remove')"
					>
						{{ button }}
					</TnButton>
				</div>
			</div>

			<div>
				<div
					v-if="tradeInIsCompleted"
					class="trade-in-card__content"
				>
					<TnParagraph
						v-if="heading"
						responsive
						bold
						size="m"
					>
						{{ heading }}
					</TnParagraph>

					<TnParagraph
						v-if="additionalHeading"
						responsive
						size="m"
					>
						{{ additionalHeading }}
					</TnParagraph>

					<TnParagraph
						v-if="subHeading"
						responsive
						size="m"
					>
						{{ subHeading }}
					</TnParagraph>

					<TradeInAccountNumber
						v-if="showAccountNumber"
						v-model:account-number="accountNumber"
					/>
				</div>
				<div
					v-else-if="showReturnToTradeInJourneyButton"
					class="trade-in-card__content__one-line"
				>
					<TnIcon name="warning-circle-filled" />

					<TnParagraph
						responsive
						size="m"
					>
						Fullfør <TnLink @click="goToTradeIn"> innbytte</TnLink> for å se verdien.
					</TnParagraph>
				</div>
			</div>
		</div>
	</TnCard>
</template>

<script setup lang="ts">
import type { TradeInPhoneData } from "~/components/hardware-and-insurance/types/TradeInPhoneData";

const props = defineProps({
	badge: {
		type: String,
		required: true,
	},
	heading: {
		type: String,
		required: true,
	},
	additionalHeading: {
		type: String,
		default: undefined,
	},
	subHeading: {
		type: String,
		required: true,
	},
	button: {
		type: String,
		required: true,
	},
	returnToTradeInJourneyLink: {
		type: String,
		required: true,
	},
	showButton: {
		type: Boolean,
		default: true,
	},
	phoneData: {
		type: Object as PropType<TradeInPhoneData>,
		required: true,
	},
	showAccountNumber: {
		type: Boolean,
		default: false,
	},
	tradeInIsCompleted: {
		type: Boolean,
		default: false,
	},
	showReturnToTradeInJourneyButton: {
		type: Boolean,
		default: false,
	},
});

defineEmits(["remove"]);

const accountNumber = defineModel("accountNumber", {
	type: String,
	default: "",
});

const route = useRoute();

const showFallback = ref(false);

const imgSrc = computed(() => props.phoneData?.imageUrl);

const goToTradeIn = () => {
	const path = route.path;
	navigateTo({ path: props.returnToTradeInJourneyLink, query: { path: path } });
};
</script>

<style scoped lang="scss">
.trade-in-card {
	padding: $spacing-2xl $spacing-m $spacing-m;

	&__content {
		display: flex;
		flex-direction: column;
		gap: $spacing-m;
		width: 100%;
		margin: $spacing-s $spacing-s 0 $spacing-s;

		&__one-line {
			display: flex;
			flex-direction: row;
			gap: $spacing-s;
			align-items: center;
		}

		&__phone {
			display: flex;
			flex-direction: row;
			gap: $spacing-s;
			align-items: center;
			justify-content: space-between;

			&__info {
				display: flex;
				flex-direction: row;
				gap: $spacing-s;
				align-items: center;

				&__img {
					min-height: 60px;
					max-height: 100px;
				}

				&__text {
					height: 100%;
					display: flex;
					flex-direction: column;
					gap: $spacing-xs;
				}
			}

			&__button {
				display: flex;
				flex-direction: column;
				gap: $spacing-xs;
			}
		}
	}
}
</style>
